$(document).ready(function () {
	//Hide element when smaller than 1025

	loadingScreen();
	setBackground();
	swiperInit();
	toggleFunc();
	tabFunc();
	backToTop();
	clickScrollNextSection();
	toggleFilter();
	scrollReveal();
});

function loadingScreen() {
	const videos = document.querySelectorAll(".loading-video");
	const overlays = document.querySelectorAll(".loading-sreen");

	videos.forEach((video, index) => {
		video.addEventListener("ended", () => {
			overlays[index].classList.add("close");
		});
	});
}
window.onscroll = function () {
	fixHeader();
	if (
		document.body.scrollTop > $(window).height() ||
		document.documentElement.scrollTop > $(window).height()
	) {
		$(".fixed-side-nav").addClass("show");
	} else {
		$(".fixed-side-nav").removeClass("show");
	}
};

function clickScrollNextSection() {
	$(".primary-banner .scroll-down").click(function () {
		let e = $(".home-video").offset().top - $("header").height();
		$("html, body").animate(
			{
				scrollTop: e,
			},
			1000
		);
	});
}

function fixHeader() {
	if ($(window).width() > 1025) {
		if (
			document.body.scrollTop > 100 ||
			document.documentElement.scrollTop > 100
		) {
			$("header").addClass("minimize");
		} else {
			$("header").removeClass("minimize");
		}
	}
}

Fancybox.bind("[data-fancybox]", {
	parentEl: document.body[0], // Element containing main structure
});

$("body").on("click", ".dealer-list .ajaxResponse li", function (e) {
	let map = $(this).attr("data-map");
	$(".dealer-list .ajaxResponse li").removeClass("is-active");
	$(this).addClass("is-active");
	$(".map-dealer iframe").attr("src", map);
});
window.onload = function () {
	// let's go!
	if ($(".dealer-list").length) {
		$(".dealer-list .ajaxResponse li").trigger("click");
	}
};

function scrollReveal() {
	let fadeDown = {
		origin: "top",
		duration: 3000,
		delay: 220,
		interval: 150,
		reset: true,
		distance: "75%",
	};
	let fadeUp = {
		origin: "bottom",
		duration: 3000,
		delay: 500,
		interval: 50,
		reset: true,
		distance: "75%",
	};
	let fadeLeft = {
		origin: "left",
		duration: 3000,
		delay: 200,
		interval: 50,
		reset: true,
		distance: "75%",
	};
	let fadeRight = {
		origin: "right",
		duration: 3000,
		delay: 200,
		interval: 50,
		reset: true,
		distance: "75%",
	};
	let fadeIn = {
		origin: "top",
		duration: 3000,
		delay: 220,
		interval: 150,
		reset: true,
		distance: 0,
	};
	ScrollReveal().reveal(".fd-down", fadeDown, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-up", fadeUp, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-left", fadeLeft, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-right", fadeRight, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
	ScrollReveal().reveal(".fd-in", fadeIn, {
		easing: "cubic-bezier(.38,.37,.42,.42)",
	});
}

function toggleFilter() {
	$(".filter-display-wrapper").each(function () {
		let x = 12;
		$(this).find(".col").slice(0, 12).show();
		let size = $(this).find(".col").length;
		if (size < x || size == x) {
			$(this).find(".ajax-center-wrap").addClass("disble-btn");
		}
		$(".filter-display-wrapper")
			.find(".btn-ajax-link")
			.on("click", function (e) {
				e.preventDefault();
				x = x + 6;
				$(this)
					.parents(".filter-display-wrapper")
					.find(".col")
					.slice(0, x)
					.slideDown();
				if (x >= size || size < x) {
					$(this).slideUp();
				}
			});
	});
}

function toggleFunc() {
	document
		.querySelector(".menu-toggle")
		.addEventListener("click", function (event) {
			document.querySelector(".hamburger").classList.toggle("is-active");
			document
				.querySelector(".navbar-primary-menu")
				.classList.toggle("open-menu");
		});
	document
		.querySelector(".search-toggle")
		.addEventListener("click", function (event) {
			document
				.querySelector(".search-overlay")
				.classList.toggle("active");
			$(".search-toggle").toggleClass("icon-change");
		});
	document.addEventListener("click", function (event) {
		if (
			!event.target.closest(".search-toggle") &&
			!event.target.closest(".search-overlay")
		) {
			document
				.querySelector(".search-overlay")
				.classList.remove("active");
			document
				.querySelector(".search-toggle")
				.classList.remove("icon-change");
		}
	});
	$(".filter-toggle").on("click", function () {
		$(".mobile-filter-wrap").toggleClass("is-open");
	});
	$(".close-filter").on("click", function () {
		$(".mobile-filter-wrap").removeClass("is-open");
	});

	if ($(window).width() < 1025) {
		$(".drop-down .title em").on("click", function () {
			if ($(this).parents(".drop-down").hasClass("is-open")) {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
			} else {
				$(".drop-down .nav-sub").slideUp();
				$(".drop-down").removeClass("is-open");
				$(this).parent().next().slideDown();
				$(this).parents(".drop-down").addClass("is-open");
			}
		});
	}

	$(".toggle-wrapper").each(function (index, element) {
		let $this = $(this);
		$this.addClass("toggle-instance-" + index);
		$(".toggle-instance-" + index + " .toggle-item .title").click(
			function () {
				if ($(this).parent().hasClass("is-toggle")) {
					$(this).parent().find(".article").slideUp();
					$(this).parent().removeClass("is-toggle");
				} else {
					$(this).parent().find(".article").slideDown();
					$(this).parent().addClass("is-toggle");
				}
			}
		);
	});
}

function swiperInit() {
	var primarySwiper = new Swiper(".primary-banner .swiper", {
		// Optional parameters
		slidesPerView: 1,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		navigation: {
			nextEl: ".primary-banner .nav-arrow-next",
			prevEl: ".primary-banner .nav-arrow-prev",
		},
		pagination: {
			el: ".primary-banner  .swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});

	var projectList = new Swiper(".project-gallery-swiper  .swiper", {
		// Optional parameters
		spaceBetween: 30,
		speed: 1205,
		centeredSlides: true,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 1,
			},
			769: {
				slidesPerView: 1.2,
			},
			1024: {
				slidesPerView: 1.7,
			},
			1440: {
				slidesPerView: 1.5,
			},
		},
		navigation: {
			nextEl: ".project-gallery-swiper .nav-next",
			prevEl: ".project-gallery-swiper .nav-prev",
		},
	});
	try {
		projectList.slideTo(1);
		projectList.autoplay.start();
	} catch (error) {}
	var aboutSwiper = new Swiper(".about-us-swiper  .swiper", {
		// Optional parameters
		spaceBetween: 4,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			320: {
				slidesPerView: 2,
			},
			769: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 2.9,
			},
		},
		pagination: {
			el: ".about-us-swiper .swiper-pagination",
			type: "progressbar",
		},
		navigation: {
			nextEl: ".about-us-swiper .nav-arrow-next",
			prevEl: ".about-us-swiper .nav-arrow-prev",
		},
	});

	var homeProduct = new Swiper(".home-product-swiper .swiper", {
		// Optional parameters
		spaceBetween: 30,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 1,
			},
			576: {
				slidesPerView: 2,
			},
			769: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 4.1,
			},
		},
		navigation: {
			nextEl: ".home-product-swiper .nav-arrow-next",
			prevEl: ".home-product-swiper .nav-arrow-prev",
		},
	});
	var productThumbs = new Swiper(".swiper-product-thumb  .swiper", {
		spaceBetween: 8,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
				direction: "horizontal",
			},
			420: {
				slidesPerView: 4,
				direction: "horizontal",
			},
			768: {
				slidesPerView: 5,
				direction: "horizontal",
			},
			1024.1: {
				slidesPerView: 5,
				direction: "vertical",
			},
			1280: {
				slidesPerView: 6,
				direction: "vertical",
			},
		},

		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var productMain = new Swiper(".swiper-product-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		navigation: {
			nextEl: ".swiper-product-main .nav-next",
			prevEl: ".swiper-product-main .nav-prev",
		},
		thumbs: {
			swiper: productThumbs,
		},
	});

	var projectThumb = new Swiper(".project-swiper-thumb  .swiper", {
		spaceBetween: 30,
		breakpointsInverse: true,
		breakpoints: {
			320: {
				slidesPerView: 3,
				spaceBetween: 10,
			},
			576: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 5,
			},
			1024.1: {
				slidesPerView: 5,
			},
		},
		watchSlidesVisibility: true,
		watchSlidesProgress: true,
	});
	var projectMain = new Swiper(".project-swiper-main .swiper", {
		slidesPerView: 1,
		centeredSlides: true,
		lazy: {
			loadPrevNext: true,
		},
		autoplay: {
			delay: 3000,
		},
		speed: 750,
		loop: false,
		navigation: {
			nextEl: ".project-swiper-thumb .nav-next",
			prevEl: ".project-swiper-thumb .nav-prev",
		},
		thumbs: {
			swiper: projectThumb,
		},
	});

	var homePartner = new Swiper(".home-partner-swiper .swiper", {
		// Optional parameters
		spaceBetween: 30,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 2,
			},
			769: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 4,
			},
			1280: {
				slidesPerView: 6,
			},
		},
		navigation: {
			nextEl: ".home-partner-swiper .nav-next",
			prevEl: ".home-partner-swiper .nav-prev",
		},
	});

	var reasonSwiper = new Swiper(".about-reason-swiper  .swiper", {
		// Optional parameters
		spaceBetween: 30,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
			},
			576: {
				slidesPerView: 2,
			},
			769: {
				slidesPerView: 3,
			},
			1024: {
				slidesPerView: 3,
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 120,
			},
		},
		navigation: {
			nextEl: ".about-reason-swiper .nav-next",
			prevEl: ".about-reason-swiper .nav-prev",
		},
	});
	var homePartner = new Swiper(".about-swiper-wrap .swiper", {
		// Optional parameters
		spaceBetween: 30,
		speed: 1205,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		breakpoints: {
			200: {
				slidesPerView: 2,
				spaceBetween: 30,
			},

			769: {
				slidesPerView: 3,
				spaceBetween: 30,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 40,
				direction: "vertical",
			},
		},
		navigation: {
			nextEl: ".about-us-4 .nav-next",
			prevEl: ".about-us-4 .nav-prev",
		},
	});

	var topBanner = new Swiper(".top-banner .swiper", {
		// Optional parameters
		speed: 1205,
		lazy: {
			loadPrevNext: true,
		},
		slidesPerView: 1,
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
	});
	$(".trinity-swiper .swiper").each(function (index, element) {
		const $element = $(element);
		const parent = $element.parent();
		const prev = parent.find(".nav-prev");
		const next = parent.find(".nav-next");
		const options = {
			speed: 750,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			breakpoints: {
				200: {
					slidesPerView: 1,
				},
				576: {
					slidesPerView: 2,
				},
				769: {
					slidesPerView: 3,
				},
				1200: {
					slidesPerView: 3,
				},
			},
			navigation: {
				nextEl: next,
				prevEl: prev,
			},
		};
		$element.addClass(`t-${index}`);
		prev.addClass(`bp-${index}`);
		next.addClass(`bn-${index}`);
		new Swiper(`.t-${index}`, {
			...options,
			navigation: {
				...options.navigation,
				nextEl: `.bn-${index}`,
				prevEl: `.bp-${index}`,
			},
		});
	});

	var swiperGrid = new Swiper(".grid-swiper .swiper", {
		autoplay: {
			delay: 3000,
			pauseOnMouseEnter: true,
		},
		lazy: {
			loadPrevNext: true,
		},
		observer: true,
		spaceBetween: 30,
		observeParents: true,
		slidesPerView: 4,
		grid: {
			rows: 2,
		},
		pagination: {
			el: ".swiper-pagination",
			clickable: true,
		},
	});
	$(".single-swiper .swiper").each(function (index, element) {
		var $this = $(this);
		$this.addClass("single-instance-" + index);
		$this
			.parent()
			.find(".nav-arrow-prev")
			.addClass("btn-prev-single-" + index);
		$this
			.parent()
			.find(".nav-arrow-next")
			.addClass("btn-next-single-" + index);
		$this
			.parent()
			.find(".swiper-pagination")
			.addClass("pagination-instance-" + index);

		var swiper = new Swiper(".single-instance-" + index, {
			speed: 1205,
			autoplay: {
				delay: 3000,
				pauseOnMouseEnter: true,
			},
			lazy: {
				loadPrevNext: true,
			},
			speed: 750,
			observer: true,
			spaceBetween: 30,
			observeParents: true,
			slidesPerView: 1,
			navigation: {
				nextEl: ".btn-next-single-" + index,
				prevEl: ".btn-prev-single-" + index,
			},
			pagination: {
				el: ".pagination-instance-" + index,
				type: "bullets",
				clickable: true,
			},
		});
	});
}
function backToTop() {
	let btn = $(".back-to-top");
	btn.on("click", function (e) {
		e.preventDefault();
		$("html, body").animate(
			{
				scrollTop: 0,
			},
			"300"
		);
	});
}

//Tab Func
function tabFunc() {
	const tabs = document.querySelectorAll(".tabs");
	tabs.forEach((tab) => {
		const tabMenuItems = tab.querySelectorAll(".tab-menu-item");

		tabMenuItems.forEach((item) => {
			item.addEventListener("click", (e) => {
				// Remove active class from all tab menu items and tab pane items within the same tab
				tab.querySelectorAll(".tab-menu-item").forEach((item) =>
					item.classList.remove("active")
				);
				tab.querySelectorAll(".tab-panel").forEach((item) =>
					item.classList.remove("active")
				);

				// Add active class to clicked tab menu item and corresponding tab pane item
				e.target.classList.add("active");
				tab.querySelector(`#${e.target.dataset.tab}`).classList.add(
					"active"
				);
			});
		});
	});
}
// Side
function setBackground() {
	const elements = document.querySelectorAll("[setBackground]");
	elements.forEach((element) => {
		element.style.cssText = `
		background-image: url(${element.getAttribute("setBackground")});
		background-size: cover;
		background-position: center;
	  `;
	});
}
